import { useMemo, useCallback } from 'react';
import { getCompanyRole } from './utils';

export function usePermission() {
  const role = getCompanyRole();
  const userPermissions = useMemo(() => {
    return role?.permissions?.map((p) => p.code) || [];
  }, [role]);

  const hasPermissions = useCallback(
    (permissions: string[] | undefined) => {
      if (!permissions) return true;

      return permissions.some((p) => userPermissions.includes(p));
    },
    [userPermissions]
  );

  return {
    hasPermissions,
  };
}

export function Can({
  permissions,
  children,
  fallback = null,
}: {
  permissions?: string[];
  children: React.ReactNode;
  fallback?: React.ReactNode;
}) {
  const { hasPermissions } = usePermission();

  return hasPermissions(permissions) ? children : fallback;
}
