import { createContext, useContext, useMemo, useReducer } from 'react';

type BreadcrumbProviderProps = { children: React.ReactNode };

export const BreadcrumbContext = createContext<
  { state: any; dispatch: any } | undefined
>(undefined);

function BreadcrumbProviderRedux(state: any, action: any) {
  return { ...state, ...action };
}

const defaultProps = {
  data: [], // can be anything
};

// used only when changing page but need to keep breadcrumb data
export function BreadcrumbProvider({ children }: BreadcrumbProviderProps) {
  const [state, dispatch] = useReducer(BreadcrumbProviderRedux, defaultProps);
  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <BreadcrumbContext.Provider value={value}>
      {children}
    </BreadcrumbContext.Provider>
  );
}

export function useBreadcrumbInfo() {
  const context = useContext(BreadcrumbContext);
  if (context === undefined) {
    throw new Error(
      'useBreadcrumbInfo must be used within a BreadcrumbProvider'
    );
  }
  return context;
}
