import axios from 'modules/axios';
import {
  IActivityPayload,
  RCSSettings,
  RespOrgSettings,
  RootResponse,
  SendFeedbackParams,
  ShareItemPayload,
} from 'types/general';
import {
  dateParser,
  getCompanyId,
  getCompanyName,
  getCompanyRole,
  getOrgSettingsFromCookies,
  getSlug,
  getUser,
} from 'helper';

export async function getOrgSettings() {
  return axios.get<RespOrgSettings>(`v1/settings?slug=${getSlug()}`);
}

export async function updateSettings(params: RCSSettings) {
  return axios.put(
    `v1/settings?slug=${getSlug()}`,
    { ...params },
    {
      params: {
        company_id: getCompanyId(),
      },
    }
  );
}

export async function shareItem(params: ShareItemPayload) {
  return axios.post(
    `v1/share`,
    { ...params },
    {
      params: {
        company_id: getCompanyId(),
      },
    }
  );
}

export async function uploadSettingsImage(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post<RootResponse<{ url: string }>>(
    `v1/settings/upload-file`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
}

export async function sendFeedback(params: SendFeedbackParams) {
  const formData = new FormData();
  formData.append('feed_type', params.feed_type);
  formData.append('feedback', params.feedback);
  formData.append('participate_survey', String(params.participate_survey));
  formData.append('user_id', params.user_id);
  formData.append('company_id', String(getCompanyId()));
  params.attachment.forEach((file) => {
    formData.append('attachment[]', file);
  });
  return axios.post('v1/feedback', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    baseURL:
      process.env.NEXT_PUBLIC_APP_ENV === 'production'
        ? '/api-membership'
        : process.env.NEXT_PUBLIC_MEMBERSHIP_API,
  });
}

export async function trackActivityLog(params: IActivityPayload) {
  const basicToken = process.env.NEXT_PUBLIC_BASIC_AUTH;
  const companyId = getCompanyId() || '';
  const companyName = getCompanyName() || '';
  const role = getCompanyRole() || '';

  const { name, username, email } = getUser() || {};
  const org = getOrgSettingsFromCookies();
  const userId = getUser()?.id || '';
  const { deviceId, deviceType, ...rest } = params;

  const payload = {
    ...rest,
    data: {
      date: dateParser('', 'YYYY-MM-DD HH:mm:ss'),
      user_name: name,
      user_email: email,
      user_privilege: (role as any)?.name,
      username,
      ...rest.data,
    },
    company_id: companyId,
    company_name: companyName,
    user_id: userId,
    device_id: deviceId,
    device_type: deviceType,
    organization_id: org?.organization_id,
    organization_name: org?.organization_name,
  };

  return await axios.post('v1/activity-log', payload, {
    headers: {
      Authorization: `Basic ${basicToken}`,
    },
  });
}
