/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import { deleteCookies, getCookies } from 'helper';

const apiUrl =
  process.env.NEXT_PUBLIC_APP_ENV === 'production'
    ? '/api-client'
    : process.env.NEXT_PUBLIC_CLIENT_API;

axios.defaults.baseURL = apiUrl;

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response !== undefined) {
      const { status, data } = error.response;
      const token = getCookies('token');
      if (
        status === 401 &&
        !error.request.responseURL.includes('/v1/document')
      ) {
        deleteCookies('token');
        deleteCookies('companies');
        deleteCookies('slug');
        if (!token) {
          window.location.href = '/login';
        }
        return Promise.reject(error);
      }
      if (status === 404) {
        window.location.href = '/page-404';
      }
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  (config) => {
    if (!config.headers['Authorization']) {
      const token = getCookies('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};
