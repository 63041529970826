import { createContext, ReactNode, useContext, useMemo } from 'react';
import { UserInfo } from 'types/user';
import { useGetUserProfile } from 'modules/personal-setting/account/hooks';

type UserProviderProps = { children: ReactNode };

export const UserInfoContext = createContext<
  { user: UserInfo | null } | undefined
>(undefined);

export function UserInfoProvider({ children }: UserProviderProps) {
  const { data } = useGetUserProfile();

  const value = useMemo<{
    user: UserInfo | null;
  }>(
    () => ({
      user: {
        id: data?.id,
        created_at: data?.created_at,
        email: data?.email,
        name: data?.name,
        role: data?.role,
        role_id: data?.role_id,
        status: data?.status,
        groups: data?.groups,
      },
    }),
    [data]
  );

  return (
    <UserInfoContext.Provider value={{ ...value }}>
      {children}
    </UserInfoContext.Provider>
  );
}

export function useUserInfo() {
  const context = useContext(UserInfoContext);
  if (context === undefined) {
    throw new Error('useUserInfo must be used within a UserInfoProvider');
  }
  return context;
}
