import { createContext, useContext, useMemo, useReducer } from 'react';
import { SideNavNavigationProps } from '../components/layout/SideNav';

type Action = true | false;
type State = {
  show: Action;
  sideNavTitle?: string;
  sideNavNavigations?: SideNavNavigationProps[];
  hideSideNav?: boolean;
  testidSidebar?: string;
};
type Dispatch = (value: State) => void;

type SideNavProviderProps = { children: React.ReactNode };

export const SideNavContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function ToggleNavbarReducer(state: State, action: State): any {
  return { ...state, ...action };
}

const defaultProps = {
  show: true,
  sideNavTitle: null,
  sideNavNavigations: null,
  hideSideNav: null,
  testidSidebar: null,
};

export function SideNavProvider({ children }: SideNavProviderProps) {
  const [state, dispatch] = useReducer(ToggleNavbarReducer, defaultProps);
  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <SideNavContext.Provider value={value}>{children}</SideNavContext.Provider>
  );
}

export function useSideNavInfo() {
  const context = useContext(SideNavContext);
  if (context === undefined) {
    throw new Error('useSideNavInfo must be used within a SideNavProvider');
  }
  return context;
}
