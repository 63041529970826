import fetch from 'cross-fetch';
import {
  ApolloClient,
  InMemoryCache,
  split,
  HttpLink,
  from,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { getCookies } from './cookiesHelper';

export const errorNotification = (title: string, error: any = {}) => {
  const objectConstructor = {}.constructor;
  let msg = error?.message;
  if (error?.errors?.constructor === objectConstructor) {
    for (const key in error.errors) {
      msg = key + ': ' + error.errors[key];
      break;
    }
  }

  return { title, msg };
};

const isBrowser = typeof window !== 'undefined';

export const urlGraphql =
  process.env.NEXT_PUBLIC_APP_ENV === 'production'
    ? `${isBrowser ? window?.location?.origin : 'http://localhost'}/graphql`
    : process.env.NEXT_PUBLIC_HOST_GRAPHQL_API || '';
export const wsGraphql =
  process.env.NEXT_PUBLIC_APP_ENV === 'production'
    ? `${isBrowser ? (window?.location?.protocol === 'https:' ? 'wss' : 'ws') : 'wss'}://${isBrowser ? window?.location?.host : 'localhost'}/graphql-ws`
    : process.env.NEXT_PUBLIC_HOST_GRAPHQL_WS_API || '';

const httpLink = new HttpLink({
  uri: urlGraphql,
  headers: {
    authorization: getCookies('token') || '',
  },
  fetch,
});

const wsLink = new WebSocketLink({
  uri: wsGraphql,
  options: {
    reconnect: true,
    connectionParams: {
      authToken: getCookies('token'),
    },
    reconnectionAttempts: 5,
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors?.[0]?.extensions?.code === 401) {
    errorNotification(
      'Session Expired',
      'Session has expired, redirecting to login'
    );
    setTimeout(() => {
      window.location.href = '/login';
    }, 1000);
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const validateLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

export const client: any = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, validateLink]),
});
