import axios from 'modules/axios';
import { getCompanyId } from 'helper/utils';
import { getCookies } from 'helper';

export const getUserProfile = async () => {
  if (!getCookies('token')) return;
  return axios
    .get<any>('/v1/users/profile', {
      params: { company_id: getCompanyId() },
    })
    .then((res) => res.data?.data);
};

export async function updateUserProfile(payload: {
  name: string;
  username?: string;
  language_preference?: 'en' | 'id';
  receive_whatsapp_notif?: boolean;
  whatsapp_number?: string;
}) {
  return axios.put(
    `v1/users/profile`,
    { ...payload },
    {
      params: {
        company_id: getCompanyId(),
      },
    }
  );
}

export const getDetailRole = async (roleId: string) =>
  axios
    .get<any>(`/v1/roles/${roleId}`, {
      params: {
        company_id: getCompanyId(),
        role_type: 'SYSTEM',
      },
    })
    .then((res) => res.data?.data);
