import Cookies from 'js-cookie';

export function getCookies(key: string): string | undefined {
  return Cookies.get(key);
}

interface setCookiesParam {
  key: string;
  value: string;
}

export function setCookies(param: setCookiesParam) {
  return Cookies.set(param.key, param.value);
}

export function deleteCookies(key: string) {
  return Cookies.remove(key);
}
